<template>
  <div class="p-4 root relative block">
    <div v-if="type === 'fillerWords'" class="font-h font-semibold text-lg text-primary">
      Filler Words
    </div>

    <div v-if="type === 'silence'" class="font-h font-semibold text-lg text-primary">
      Silences
    </div>

    <!-- result list -->
    <!-- TODO: move list item to be a common component -->
    <div v-if="results.length" class="list mt-3 pt-1.5 pr-5 pb-4 overflow-y-auto">
      <div v-for="(item, index) in results" :key="item.key" class="group relative">
        <div
          :class="['mb-2 px-2.25 py-2.5 flex items-center justify-between rounded cursor-pointer border hover:border-primary', item.isDeleted ? 'bg-primary-100 border-primary-200' : 'border-primary-300', selected === item.key ? 'border-primary' : '']"
          @click="goToInstance(item.location, item.key)"
        >
          <div class="flex items-center listText">
            <div v-if="editMode === 'editMedia'" :class="['text-2xs rounded p-2 mr-2', item.isDeleted ? 'bg-primary-400 text-primary-700' : 'bg-primary text-white']">
              {{item.atTime}}
            </div>

            <div
              class="text-xs leading-6 whitespace-nowrap overflow-ellipsis overflow-hidden"
              :class="[item.isDeleted ? 'text-primary-600' : 'text-gray-500']"
            >
              <span
                v-for="part in item.stringParts"
                :key="part.value"
                :class="{
                  'bg-primary-400 deletedHighlight': part.highlight && item.isDeleted,
                  'noHighlight': part.highlight && !item.isDeleted && type === 'silence',
                  'text-red highlight': part.highlight && !item.isDeleted && type !== 'silence',
                }"
              >
                {{part.value}}
              </span>

              <!-- <span :class="[item.isDeleted ? 'bg-primary-400 deletedHighlight' : (type === 'silence' ? 'noHighlight' : 'text-red highlight')]">
                {{item.searchWord}}
              </span>
              <span>
                {{item.stringWithoutSearchWord}}
              </span> -->
            </div>
          </div>
        </div>

        <div v-if="item.isDeleted" :class="['absolute z-10  cursor-pointer text-primary-400 hover:text-green', editMode === 'editMedia' ? 'editMediaActionIcon' : 'actionIcon' ]" @click="restoreWord(item.location, index, item.key)">
          <RestoreIcon class="h-4.5 w-4.5" />
        </div>
        <div v-else :class="['absolute z-10  cursor-pointer text-primary-400 hover:text-red', editMode === 'editMedia' ? 'editMediaActionIcon' : 'actionIcon']" @click="deleteWord(item.location, index, item.key)">
          <DeleteIcon class="h-4.5 w-4.5" />
        </div>
      </div>
    </div>

    <!-- Action buttons section -->
    <div class="bg-white flex p-2 right-0 bottom-0 left-0 z-10 topShadow absolute items-center justify-between">

      <div class="text-sm pl-0.5">
        <span class="font-bold text-cobalt">{{results.length}}</span> instances
      </div>

      <div v-if="results.length">
        <Button
          :onClick="toggleSmartEdit"
        >
          {{buttonText}}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  removeAllSearchHighlights,
  restoreWordAtLocations,
  deleteWordAtLocations,
  addCustomHighlight,
  removeAllSelections
} from '@/view/voiceEditor/proseEditor/util/editorSearchUtility'
import {
  highlightFillerWordInDoc,
  searchContinuousSilence
} from '@/view/voiceEditor/proseEditor/util/smartEditUtilities'

import DeleteIcon from '@/components/base/icons/Delete.vue'
import RestoreIcon from '@/components/base/icons/Restore.vue'
import Button from '@/components/base/buttons/Button.vue'
import { humanLength } from '@/utilities/utility'
import { myProseEditor, scrollToLocation } from '@/view/voiceEditor/proseEditor/util/utility'
import store from '@/services/store'
// eslint-disable-next-line import/no-webpack-loader-syntax
import worker from 'workerize-loader!../../../proseEditor/util/editorSearchUtilityWorker'
let editorSearchUtilityWorkerInstance = worker()

const generateParts = (resultString, highlightString) => {
  let parts = resultString.split(highlightString)
  if (parts.length === 2) {
    parts = [{
      value: parts[0]
    }, {
      value: highlightString,
      highlight: true
    },
    {
      value: parts[1]
    }]
  } else if (resultString.startsWith(highlightString)) {
    parts = [{
      value: highlightString,
      highlight: true
    }, {
      value: parts[0]
    }]
  } else {
    parts = [{
      value: parts[0]
    }, {
      value: highlightString,
      highlight: true
    }]
  }

  return parts
}

const getResultsForUI = (list) => list.map(t => ({
  ...t,
  atTime: humanLength(Math.round(t.meta && t.meta.silenceDurationInSeconds ? t.meta.silenceDurationInSeconds : t.atTime)),
  key: `${t.location.start}-${t.location.end}`,
  stringWithoutSearchWord: t.resultString.replace(t.searchWord, ''),
  stringParts: generateParts(t.resultString, t.searchWord)
}))

export default {
  data: function() {
    return {
      results: [],
      selected: null
    }
  },
  components: {
    DeleteIcon,
    RestoreIcon,
    Button
  },
  props: {
    type: {
      type: String,
      default: 'fillerWords'
    }
  },
  computed: {
    ...mapGetters({
      'fillerWords': 'smartEditConfig/fillerWords',
      'trimSilence': 'smartEditConfig/trimSilence',
      'editMode': 'editor/editMode'
    }),
    isEnabled: function() {
      if (this.type === 'fillerWords') return this.fillerWords.isEnabled
      if (this.type === 'silence') return this.trimSilence.isEnabled
      return false
    },
    buttonText: function() {
      return this.isEnabled ? 'Restore all' : 'Remove all'
    }
  },
  methods: {
    ...mapActions({
      setFillerWordsIsEnabled: 'smartEditConfig/setFillerWordsIsEnabled',
      setTrimSilenceIsEnabled: 'smartEditConfig/setTrimSilenceIsEnabled'
    }),
    goToInstance: function(location, key) {
      removeAllSelections()
      scrollToLocation(location)
      addCustomHighlight(location.start, location.end, 'searchSelection')
      this.selected = key
    },
    toggleSmartEdit: function() {
      if (this.type === 'fillerWords') {
        let val = !this.isEnabled
        store.commit('smartEditConfig/setFillerWordIsEnabled', val)
        store.dispatch('editor/setEditorBlocked', { value: true, reason: 'Autopilot Processing...' })
        editorSearchUtilityWorkerInstance.multipleSearchInDocWorker(myProseEditor.view.state.doc.toJSON(), store.state.smartEditConfig.fillerWords.words).then(
          result => {
            let finalResult = result.filter(u => !u.isRestored)
            this.setFillerWordsIsEnabled({
              ownerId: this.$route.params.userId,
              podId: this.$route.params.podId,
              isEnabled: val,
              searchResult: finalResult
            })
            store.dispatch('editor/setEditorBlocked', { value: false, reason: 'Autopilot Processing...' })
            this.research()
          }
        )
        // this.setFillerWordsIsEnabled({
        //   podId: this.$route.params.podId,
        //   isEnabled: !this.isEnabled
        // })
      }
      if (this.type === 'silence') {
        let val = !this.isEnabled
        store.commit('smartEditConfig/setTrimSilenceIsEnabled', val)
        store.dispatch('editor/setEditorBlocked', { value: true, reason: 'Autopilot Processing...' })
        if (val) {
          editorSearchUtilityWorkerInstance.searchContinuousSilence(myProseEditor.view.state.doc.toJSON(), store.state.smartEditConfig.silenceUpto).then(
            result => {
              this.setTrimSilenceIsEnabled({
                ownerId: this.$route.params.userId,
                podId: this.$route.params.podId,
                isEnabled: val,
                searchResult: result
              })
              store.dispatch('editor/setEditorBlocked', { value: false, reason: 'Autopilot Processing...' })
              this.research()
            }
          )
        } else {
          editorSearchUtilityWorkerInstance.searchSmartEditAppliedSilence(myProseEditor.view.state.doc.toJSON()).then(
            result => {
              this.setTrimSilenceIsEnabled({
                ownerId: this.$route.params.userId,
                podId: this.$route.params.podId,
                isEnabled: val,
                searchResult: result
              })
              store.dispatch('editor/setEditorBlocked', { value: false, reason: 'Autopilot Processing...' })
              this.research()
            }
          )
        }
        // this.setTrimSilenceIsEnabled({
        //   podId: this.$route.params.podId,
        //   isEnabled: !this.isEnabled
        // })
      }
    },
    research: function() {
      removeAllSearchHighlights()
      this.results = getResultsForUI(this.type === 'silence' ? searchContinuousSilence() : highlightFillerWordInDoc())
    },
    restoreWord: function(location, index, key) {
      restoreWordAtLocations([location])
      this.research()
      this.goToInstance(location, key)
    },
    deleteWord: function(location, index, key) {
      deleteWordAtLocations([location])
      this.research()
      this.goToInstance(location, key)
    }
  },
  mounted: function () {
    this.research()
  },
  watch: {
    type: function (val, oldVal) {
      if (val !== oldVal) this.research()
    },
    isEnabled: function(val, oldVal) {
      if (val !== oldVal) this.research()
    }
  },
  beforeDestroy: function() {
    removeAllSearchHighlights()
  }
}
</script>

<style scoped lang="css">
.root {
  height: 100%;
}

.list {
  height: calc(100% - 84px);
}

.editMediaActionIcon {
  top: 16px;
  right: 14px;
}

.actionIcon {
  top: 14px;
  right: 14px;
}

.listText {
  width: calc(100% - 32px);
}

.deletedHighlight {
  padding: 3px 0 3px 3px;
  margin-right: 3px;
  border-radius: 2px;
}

.highlight {
  padding: 3px 0 3px 3px;
  margin-right: 3px;
  border-radius: 2px;
  background: rgba(248, 196, 80, 0.5);
}

.topShadow {
  box-shadow: 0px -8px 8px rgba(0, 1, 20, 0.08);
}
</style>
